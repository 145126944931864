import dayjs from 'dayjs';

/*************************************************************
 * 検索表示件数のすべて の場合の数値
 * firestoreのlimitに使用 最大値は10000
 *************************************************************/
export const ALL_SELECT_NUM = 10000;

/*************************************************************
 * 日付のバリデーションチェック
 *************************************************************/
export const validDate = (year, month, date) => {
  let addMonth = parseInt(month, 10) + 1;
  let addDate = date;
  if (addMonth < 10) {
    addMonth = '0' + addMonth;
  }
  if (addDate < 10) {
    addDate = '0' + addDate;
  }
  // ex.) '2020/02/31'の文字列を作成
  const formatDate = `${year}/${addMonth}/${addDate}`;
  // ex.) formatで変換された'2020/03/02' と '2020/02/31'の文字列を比較し、ズレていたら存在しない日付ということ
  return dayjs(formatDate, 'YYYY/MM/DD').format('YYYY/MM/DD') === formatDate;
};

/*************************************************************
 * 年の取得
 *************************************************************/
export const thisYear = () => {
  return dayjs().year(); //今年取得
  // const thisMonth = dayjs().month(); //今何月？
};

export const thisMonth = () => {
  return dayjs().month();

  // const thisMonth = dayjs().month(); //今何月？
};

export const thisDate = () => {
  return dayjs().date();
  // const thisMonth = dayjs().month(); //今何月？
};

/*************************************************************
 * 年の表示 （汎用的なセレクトボックス用）
 * @return 	(array)
 *************************************************************/
export const optionYearLoopForSelectBox = () => {
  // 昔から1年先までを表示する
  const today = new Date();
  const start = 2020;
  const end = today.getFullYear() + 1;

  // 初期値を設定
  let opt = [];
  for (let i = start; i <= end; i = (i + 1) | 0) {
    opt.push(parseInt(i, 10));
  }
  return opt;
};

/*************************************************************
 * 月、日の表示 （汎用的なセレクトボックス用）
 *
 * @param 	start(string)：スタートする月日,
 *          end(string)：終了する月日
 * @return 	(object)
 *************************************************************/
export const optionLoopForSelectBox = (start, end) => {
  // 初期値を設定
  let opt = [];
  for (let i = start; i <= end; i = (i + 1) | 0) {
    opt.push(i);
    // if (i < 10) {
    //   opt.push('0' + i);
    // } else {
    //   opt.push(i);
    // }
  }
  return opt;
};

/*************************************************************
 * where句に用いる、本日の日付取得
 *
 * @param 	なし
 * @return 	date (Timestamp)
 *************************************************************/
export const getToday = () => {
  return new Date();
};

/*************************************************************
 * ストレージファイル名に使用する拡張子を除いたファイル名
 *
 * @param 	なし
 * @return 	string(ファイル名)
 *************************************************************/
export const createFileBaseName = (str) => {
  let base = new String(str).substring(str.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') != -1) base = base.substring(0, base.lastIndexOf('.'));
  return base;
};
/*************************************************************
 * ストレージファイル名に使用するランダム文字列を入れた名前
 *
 * @param 	なし
 * @return 	string(ファイル名)
 *************************************************************/
export const createStorageName = (fileName) => {
  const timestamp = dayjs().format('YYYYMMDDhhmmss');
  const baseName = createFileBaseName(fileName);
  const extend = fileName.split('.').pop(); //拡張子の取得

  return baseName + '_' + timestamp + extend;
};

/*************************************************************
 * 検索エリアの一覧
 * @param 	array
 *************************************************************/
export const searchList = [
  {
    label: '大和市',
    options: [
      'つきみ野駅周辺',
      '中央林間駅周辺',
      '南林間駅周辺',
      '鶴間駅周辺',
      '大和駅周辺',
      '深見・代官・相模大塚',
      '桜ヶ丘駅周辺',
      '高座渋谷駅周辺',
    ],
  },
  { label: '綾瀬市', options: ['北部', '中央部', '南部'] },
];

/*************************************************************
 * datalistのoptionタグを生成,5分単位で00:00 から 23:55まで
 * @param 	array
 *************************************************************/
export const createTimeListOption5Min = [
  '0:00',
  '0:05',
  '0:10',
  '0:15',
  '0:20',
  '0:25',
  '0:30',
  '0:35',
  '0:40',
  '0:45',
  '0:50',
  '0:55',
  '1:00',
  '1:05',
  '1:10',
  '1:15',
  '1:20',
  '1:25',
  '1:30',
  '1:35',
  '1:40',
  '1:45',
  '1:50',
  '1:55',
  '2:00',
  '2:05',
  '2:10',
  '2:15',
  '2:20',
  '2:25',
  '2:30',
  '2:35',
  '2:40',
  '2:45',
  '2:50',
  '2:55',
  '3:00',
  '3:05',
  '3:10',
  '3:15',
  '3:20',
  '3:25',
  '3:30',
  '3:35',
  '3:40',
  '3:45',
  '3:50',
  '3:55',
  '4:00',
  '4:05',
  '4:10',
  '4:15',
  '4:20',
  '4:25',
  '4:30',
  '4:35',
  '4:40',
  '4:45',
  '4:50',
  '4:55',
  '5:00',
  '5:05',
  '5:10',
  '5:15',
  '5:20',
  '5:25',
  '5:30',
  '5:35',
  '5:40',
  '5:45',
  '5:50',
  '5:55',
  '6:00',
  '6:05',
  '6:10',
  '6:15',
  '6:20',
  '6:25',
  '6:30',
  '6:35',
  '6:40',
  '6:45',
  '6:50',
  '6:55',
  '7:00',
  '7:05',
  '7:10',
  '7:15',
  '7:20',
  '7:25',
  '7:30',
  '7:35',
  '7:40',
  '7:45',
  '7:50',
  '7:55',
  '8:00',
  '8:05',
  '8:10',
  '8:15',
  '8:20',
  '8:25',
  '8:30',
  '8:35',
  '8:40',
  '8:45',
  '8:50',
  '8:55',
  '9:00',
  '9:05',
  '9:10',
  '9:15',
  '9:20',
  '9:25',
  '9:30',
  '9:35',
  '9:40',
  '9:45',
  '9:50',
  '9:55',
  '10:00',
  '10:05',
  '10:10',
  '10:15',
  '10:20',
  '10:25',
  '10:30',
  '10:35',
  '10:40',
  '10:45',
  '10:50',
  '10:55',
  '11:00',
  '11:05',
  '11:10',
  '11:15',
  '11:20',
  '11:25',
  '11:30',
  '11:35',
  '11:40',
  '11:45',
  '11:50',
  '11:55',
  '12:00',
  '12:05',
  '12:10',
  '12:15',
  '12:20',
  '12:25',
  '12:30',
  '12:35',
  '12:40',
  '12:45',
  '12:50',
  '12:55',
  '13:00',
  '13:05',
  '13:10',
  '13:15',
  '13:20',
  '13:25',
  '13:30',
  '13:35',
  '13:40',
  '13:45',
  '13:50',
  '13:55',
  '14:00',
  '14:05',
  '14:10',
  '14:15',
  '14:20',
  '14:25',
  '14:30',
  '14:35',
  '14:40',
  '14:45',
  '14:50',
  '14:55',
  '15:00',
  '15:05',
  '15:10',
  '15:15',
  '15:20',
  '15:25',
  '15:30',
  '15:35',
  '15:40',
  '15:45',
  '15:50',
  '15:55',
  '16:00',
  '16:05',
  '16:10',
  '16:15',
  '16:20',
  '16:25',
  '16:30',
  '16:35',
  '16:40',
  '16:45',
  '16:50',
  '16:55',
  '17:00',
  '17:05',
  '17:10',
  '17:15',
  '17:20',
  '17:25',
  '17:30',
  '17:35',
  '17:40',
  '17:45',
  '17:50',
  '17:55',
  '18:00',
  '18:05',
  '18:10',
  '18:15',
  '18:20',
  '18:25',
  '18:30',
  '18:35',
  '18:40',
  '18:45',
  '18:50',
  '18:55',
  '19:00',
  '19:05',
  '19:10',
  '19:15',
  '19:20',
  '19:25',
  '19:30',
  '19:35',
  '19:40',
  '19:45',
  '19:50',
  '19:55',
  '20:00',
  '20:05',
  '20:10',
  '20:15',
  '20:20',
  '20:25',
  '20:30',
  '20:35',
  '20:40',
  '20:45',
  '20:50',
  '20:55',
  '21:00',
  '21:05',
  '21:10',
  '21:15',
  '21:20',
  '21:25',
  '21:30',
  '21:35',
  '21:40',
  '21:45',
  '21:50',
  '21:55',
  '22:00',
  '22:05',
  '22:10',
  '22:15',
  '22:20',
  '22:25',
  '22:30',
  '22:35',
  '22:40',
  '22:45',
  '22:50',
  '22:55',
  '23:00',
  '23:05',
  '23:10',
  '23:15',
  '23:20',
  '23:25',
  '23:30',
  '23:35',
  '23:40',
  '23:45',
  '23:50',
  '23:55',
];

// 10分単位で00:00 から 23:50まで
export const createTimeListOption10Min = [
  '0:00',
  '0:10',
  '0:20',
  '0:30',
  '0:40',
  '0:50',
  '1:00',
  '1:10',
  '1:20',
  '1:30',
  '1:40',
  '1:50',
  '2:00',
  '2:10',
  '2:20',
  '2:30',
  '2:40',
  '2:50',
  '3:00',
  '3:10',
  '3:20',
  '3:30',
  '3:40',
  '3:50',
  '4:00',
  '4:10',
  '4:20',
  '4:30',
  '4:40',
  '4:50',
  '5:00',
  '5:10',
  '5:20',
  '5:30',
  '5:40',
  '5:50',
  '6:00',
  '6:10',
  '6:20',
  '6:30',
  '6:40',
  '6:50',
  '7:00',
  '7:10',
  '7:20',
  '7:30',
  '7:40',
  '7:50',
  '8:00',
  '8:10',
  '8:20',
  '8:30',
  '8:40',
  '8:50',
  '9:00',
  '9:10',
  '9:20',
  '9:30',
  '9:40',
  '9:50',
  '10:00',
  '10:10',
  '10:20',
  '10:30',
  '10:40',
  '10:50',
  '11:00',
  '11:10',
  '11:20',
  '11:30',
  '11:40',
  '11:50',
  '12:00',
  '12:10',
  '12:20',
  '12:30',
  '12:40',
  '12:50',
  '13:00',
  '13:10',
  '13:20',
  '13:30',
  '13:40',
  '13:50',
  '14:00',
  '14:10',
  '14:20',
  '14:30',
  '14:40',
  '14:50',
  '15:00',
  '15:10',
  '15:20',
  '15:30',
  '15:40',
  '15:50',
  '16:00',
  '16:10',
  '16:20',
  '16:30',
  '16:40',
  '16:50',
  '17:00',
  '17:10',
  '17:20',
  '17:30',
  '17:40',
  '17:50',
  '18:00',
  '18:10',
  '18:20',
  '18:30',
  '18:40',
  '18:50',
  '19:00',
  '19:10',
  '19:20',
  '19:30',
  '19:40',
  '19:50',
  '20:00',
  '20:10',
  '20:20',
  '20:30',
  '20:40',
  '20:50',
  '21:00',
  '21:10',
  '21:20',
  '21:30',
  '21:40',
  '21:50',
  '22:00',
  '22:10',
  '22:20',
  '22:30',
  '22:40',
  '22:50',
  '23:00',
  '23:10',
  '23:20',
  '23:30',
  '23:40',
  '23:50',
];
