import useSWR from 'swr';
import Router from 'next/router';
import firebase, { db, auth, storage } from '@/firebase/clientApp';
import dayjs from 'dayjs';
import customizeAxios from '@/lib/customizeAxios';
import * as cf from '@/lib/clientFunction';
import * as Sentry from '@sentry/nextjs';
import { act } from 'react';

// useSWR用のfetch(黄)
export const fetcher = (url) =>
  fetch(url).then(async (res) => {
    const result = await res.json();

    if (res.status >= 500 && res.status < 600) {
      if (process.env.NODE_ENV === 'production') {
        Router.push('/systemerror');
      } else {
        alert('ページ遷移！500');
      }
      return null;
    } else if (res.status === 401) {
      if (process.env.NODE_ENV === 'production') {
        Router.push('/sessionerror');
      } else {
        alert('ページ遷移！401');
        Router.push('/sessionerror');
      }
      return null;
    } else if (res.status === 400 || (res.status >= 402 && res.status < 500)) {
      return Promise.reject(result);
    } else {
      return result;
    }
  });

// Sentryへシステムエラーの送信
export const errorFunction = (logMessage, error) => {
  process.env.NODE_ENV !== 'production' && console.error(logMessage + error);
  Sentry.captureException(logMessage + error);
};

/////////////////// 共通 ///////////////////
// // firebaseを使ってログインしているか確認
export const useLogin = async (data) => {
  try {
    // APIでトークンの発行
    const result = await customizeAxios({
      method: 'post',
      url: '/api/login',
      data: data,
    });
    if (result.status === 400) {
      return { status: result.status, message: result.message };
    } else if (result.status !== 200) {
      throw new Error(result.message); //500エラー含む
    } else if (result.status === 200) {
      // フロントからauthへログイン
      const userCredential = await auth.signInWithCustomToken(result.data.token);
      // const hoge = await auth.currentUser.getIdTokenResult(true);
      return { status: 200, data: userCredential.user.uid };
    }
  } catch (error) {
    errorFunction('useLoginログインエラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// // // ログアウト
export const useLogout = async () => {
  try {
    await auth.signOut();
    return { status: 200, data: true };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return {
      status: 500,
      message:
        'システムエラーが発生しました。管理者へエラーの通知を送信したため、しばらく経ってから再度表示してください',
    };
  }
};

// エラーテスト用のお知らせ取得
export const useGetError = async () => {
  try {
    throw new Error('管理者システムエラーテスト本番用');

    return { status: 200, data: 'OK' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return {
      status: 500,
      message:
        'システムエラーが発生しました。管理者へエラーの通知を送信したため、しばらく経ってから再度表示してください',
    };
  }
};

// DBへデータを登録する前のログイン確認
const validFirebaseAuth = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (!user) {
          resolve(null);
        } else {
          // firebaseからデータを取得
          const doc = await db.collection('admin').doc(user.uid).get();
          if (!doc.exists) {
            resolve(null); // ユーザーがない
          } else {
            // 取得したデータの格納
            const data = doc.data();
            if (!data || data.active === false) {
              resolve(null); // ユーザーがない
            } else {
              const result = {
                uid: doc.id,
                name: data.name,
                level: data.level,
              };
              // user オブジェクトを resolve
              resolve(result);
            }
          }
        }
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  });
};

// ファイルアップロード
const uploadFiles = (image) => {
  return new Promise((resolve, reject) => {
    const storage_data = image.storage; // ストレージに保存するデータ
    const name = image.document.storage_name; // DBドキュメントに保存したストレージファイル名
    const storageRef = storage.ref('images/members/'); //どのフォルダの配下に入れるかを設定
    const imagesRef = storageRef.child(name); //ストレージ用のファイル名を設定
    // ストレージにアップする
    const upLoadTask = imagesRef.put(storage_data);

    upLoadTask.on(
      'state_changed',
      function (snapshot) {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log(percent + '% done');
      },
      (error) => {
        console.error('ファイルアップに失敗しました。', error);
        reject();
      },
      () => {
        // complete: 完了したときの処理
        resolve();
      }
    );
  });
};

// お知らせ全件の取得
export const useGetNoticesList = async () => {
  try {
    const snapshot = await db
      .collection('general_categories')
      .doc('notices')
      .collection('articles')
      .where('active', '==', true)
      .orderBy('view_date', 'desc')
      .orderBy('updated_date', 'desc')
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      result.push({
        id: doc.id, // DocumentID
        view_flg: doc.data().view_flg, // 下書きか公開か
        title: doc.data().title, // 件名
        date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'),
      });
    });
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// id からお知らせの取得
export const useGetGeneralArticle = async (category, id) => {
  try {
    // URLが正しくない場合は空で返す
    if (!id || !category) return { status: 200, data: {} };

    const doc = await db
      .collection('general_categories')
      .doc(category)
      .collection('articles')
      .doc(id)
      .get();

    // データがないときは空で返す
    if (!doc.exists) {
      return { status: 200, data: {} };
    }

    // 取得したデータの格納
    const data = doc.data();
    // activeがfalseの場合は空で返す
    if (!data || data.active === false) {
      return { status: 200, data: {} };
    }
    // データがあったら返す
    const result = {
      id: doc.id, // DocumentID
      view_flg: data.view_flg, // 公開状態
      title: data.title, // 件名
      view_date: doc.data().view_date.toDate(), // 公開日
      contents: data.contents, // 本文
      updated_date: dayjs(data.updated_date.toDate()).format('YYYY/MM/DD HH:mm'), // 更新日
    };
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// お知らせの編集
export const useSetGeneralArticle = async ({ category, id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }
    // URLが正しくない場合はnullで返す
    if (!id || !category)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    const data = {
      title: body.title, // 件名
      view_flg: body.view_flg === 'true', // 公開日
      view_date: body.view_date, // 公開日
      contents: body.contents, // 本文
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      active: true,
    };
    await db
      .collection('general_categories')
      .doc(category)
      .collection('articles')
      .doc(id)
      .set(data);

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};

// お知らせの新規登録
export const useAddGeneralArticle = async ({ category, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    const validCategory = await db.collection('general_categories').doc(category).get();
    // URLが正しくない場合は400で返す
    if (!validCategory.data())
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    const data = {
      title: body.title, // 件名
      view_flg: body.view_flg === 'true', // 公開フラグ
      view_date: body.view_date, // 公開日
      contents: body.contents, // 本文
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      active: true,
    };
    await db.collection('general_categories').doc(category).collection('articles').add(data);

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};

// お知らせの削除
export const useSetDeleteGeneralArticle = async ({ category, id, body }) => {
  try {
    // URLが正しくない場合はnullで返す
    if (!id || !category)
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };

    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    const data = {
      active: false,
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
    };
    await db
      .collection('general_categories')
      .doc(category)
      .collection('articles')
      .doc(id)
      .set(data, { merge: true }); // merge: true はその項目のみ上書きする、他の項目は変更しない

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、更新できませんでした。' };
  }
};

// カテゴリ名からカテゴリの名前を取得
export const useGetCategory = async (category) => {
  try {
    // カテゴリ情報の取得
    const doc = await db.collection('general_categories').doc(category).get();

    // 取得したデータの格納
    const result = doc.data();

    if (!result) {
      return {
        status: 400,
        message: 'URLが無効です',
      };
    }

    return { status: 200, data: {} };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// --------会員---------------- //

// サイドメニュー用の会員カテゴリとサブカテゴリの全データを取得
export const useGetCategoriesAll = async () => {
  try {
    const today = cf.getToday(); // 今日の日付
    // URLが正しくない場合は空で返す

    const snapshot = await db
      .collection('members_categories')
      .where('active', '==', true)
      .orderBy('seq')
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(async function (doc) {
      // サブカテゴリをseqの順番にソートする
      if (typeof doc.data().sub_categories[0].sub_seq !== 'undefined') {
        const sortedSubCategores = doc.data().sub_categories.sort((a, b) => a.sub_seq - b.sub_seq);
        result.push({
          name: doc.data().name, // メインの英語名
          Japanese_name: doc.data().Japanese_name, // メインの日本語名
          sub_categories: sortedSubCategores, // サブカテゴリの情報
        });
      } else {
        // Seqがなかったら保存する
        const currentId = doc.id;

        const newSeq = doc.data().sub_categories.map((val, index) => {
          return {
            ...val,
            sub_seq: index, //seqが無い場合は配列順で作成する
          };
        });
        // seqを一度セットする
        const setData = {
          sub_categories: newSeq, // サブカテゴリのみ変更
        };
        await db.collection('members_categories').doc(currentId).set(setData, { merge: true });
        // 新しいサブカテゴリでデータを返す
        result.push({
          name: doc.data().name, // メインの英語名
          Japanese_name: doc.data().Japanese_name, // メインの日本語名
          sub_categories: newSeq, // サブカテゴリの情報
        });
      }
    });

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// カテゴリから記事一覧の取得
export const useGetMembersArticlesList = async (main, sub) => {
  try {
    const result = {}; // 取得するデータの格納
    result.category = []; // カテゴリを格納
    result.list = []; // 記事一覧を格納

    const today = cf.getToday(); // 今日の日付

    // URLが正しくない場合は空で返す
    if (!main || !sub) return { status: 200, data: [] };

    // カテゴリが正しいか確認
    const snapshot1 = await db
      .collection('members_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    // 取得したデータの格納
    snapshot1.forEach(function (doc) {
      result.category.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub), //該当するサブカテゴリのみ抽出
      });
    });

    // メインカテゴリがなかったら空で返す
    if (result.category.length !== 1) return { status: 200, data: [] };
    // サブカテゴリがなかったら空で返す
    if (result.category.length >= 1 && result.category[0].sub_categories === undefined) {
      return { status: 200, data: [] };
    }

    // カテゴリから記事一覧を取得
    const snapshot2 = await db
      .collection('members_articles')
      .where('main', '==', main)
      .where('sub', '==', sub)
      .where('active', '==', true)
      .orderBy('view_date', 'desc')
      .orderBy('updated_date', 'desc')
      .get();

    snapshot2.forEach(function (doc) {
      result.list.push({
        id: doc.id, // DocumentID
        title: doc.data().title, // 件名
        view_flg: doc.data().view_flg, // 公開フラグ
        view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
        main: doc.data().main, // メインカテゴリの英語名
        sub: doc.data().sub, // サブカテゴリの英語名
        main_Japanese: doc.data().main_Japanese, // メインカテゴリの日本語名
        sub_Japanese: doc.data().main_Japanese, // サブカテゴリの日本語名
      });
    });

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 新規登録ページアクセス時の、会員カテゴリの確認
export const useGetMembersCategories = async (main, sub) => {
  try {
    // URLが正しくない場合は空で返す
    if (!main || !sub)
      return {
        status: 400,
        message: 'URLが無効です',
      };

    // カテゴリが正しいか確認
    const snapshot = await db
      .collection('members_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      result.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub), //該当するサブカテゴリのみ抽出
      });
    });

    // メインカテゴリがなかったら空で返す
    if (result.length !== 1)
      return {
        status: 400,
        message: 'URLが無効です',
      };

    // サブカテゴリがなかったら空で返す
    if (result.length >= 1 && result[0].sub_categories === undefined) {
      return {
        status: 400,
        message: 'URLが無効です',
      };
    }

    return { status: 200, data: [] };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 会員記事の新規登録
export const useAddMembersArticle = async ({ main, sub, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    if (!main || !sub)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // カテゴリが正しいか確認
    const snapshot1 = await db
      .collection('members_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    const category = []; // 取得するデータの格納
    snapshot1.forEach(function (doc) {
      category.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub), //該当するサブカテゴリのみ抽出
      });
    });

    // メインカテゴリがなかったら400で返す
    if (category.length !== 1)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };
    // サブカテゴリがなかったら400で返す
    if (category.length >= 1 && category[0].sub_categories === undefined) {
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    //// 記事の登録と、Storageへの登録 ////
    // 1.refの作成
    const ref = db.collection('members_articles').doc();
    // 2.ファイルデータの抜き出し
    const document_files = body.files.map((file) => {
      return file.document;
    });

    // 2.記事データの作成
    const data = {
      title: body.title, // 件名
      view_flg: body.view_flg === 'true', // 公開フラグ
      view_date: body.view_date, // 公開日
      contents: body.contents, // 本文
      files: document_files, //添付ファイル情報
      main: main, // メインカテゴリ
      sub: sub, // サブカテゴリ
      main_Japanese: category[0].Japanese_name, // メインカテゴリ日本語名
      sub_Japanese: category[0].sub_categories.Japanese_name, //サブカテゴリ日本語名
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      active: true,
    };
    // トランザクション開始
    return db
      .runTransaction(async (t) => {
        // 記事の登録
        t.set(ref, data);

        // storageの登録
        for (let i = 0; i < body.files.length; i = (i + 1) | 0) {
          // アップロード処理
          const image = body.files[i];
          await uploadFiles(image);
        }

        return { status: 200, message: '' };
      })
      .catch((error) => {
        // This will be an "population is too big" error.
        console.error(error);
        throw new Error(error);
      });
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};

// 会員記事のデータ取得（更新アクセス時）
export const useGetMembersArticle = async (main, sub, id) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }
    // URLが正しくない場合は空で返す
    if (!id || !main || !sub) return { status: 200, data: {} };

    const doc = await db.collection('members_articles').doc(id).get();

    // データがないときは空で返す
    if (!doc.exists) {
      return { status: 200, data: {} };
    }

    // 取得したデータの格納
    const data = doc.data();
    // activeがfalse、またはカテゴリが相違の場合は空で返す
    if (!data || data.active === false || data.main !== main || data.sub !== sub) {
      return { status: 200, data: {} };
    }

    // データがあったら返す
    const result = {
      id: doc.id,
      title: data.title, // タイトル
      view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
      updated_date: dayjs(data.updated_date.toDate()).format('YYYY/MM/DD HH:mm'), // 更新日
      contents: data.contents, // 本文
      view_flg: data.view_flg, //公開フラグ
      main: data.main,
      files: [],
    };
    // file情報があったら
    if (data.files.length >= 1) {
      // seqがある場合はseqで並び替えをする
      if (data.files[0].seq) {
        //先頭のファイルにseqが存在した場合
        result.files = data.files.sort((a, b) => a.seq - b.seq); //seq順でソートする
      } else {
        const newSeqFiles = data.files.map((file, index) => {
          return {
            file_name: file.file_name,
            storage_name: file.storage_name,
            seq: index + 1, //seqが無い場合は配列順で作成する
            file_size: file.file_size,
          };
        });
        // seqを一度セットする
        const setData = {
          updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
          updated_user_name: user.name, //データ更新者
          updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
          files: newSeqFiles, // 添付ファイル
        };
        await db.collection('members_articles').doc(id).set(setData, { merge: true });
        result.files = newSeqFiles; // seqを追加しファイル情報
      }
    }
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 会員記事の編集
export const useSetMembersArticle = async ({ main, sub, id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    if (!main || !sub || !id)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // 2.記事データの作成
    const data = {
      title: body.title, // 件名
      view_flg: body.view_flg === 'true', // 公開フラグ
      view_date: body.view_date, // 公開日
      contents: body.contents, // 本文
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      active: true,
    };
    await db.collection('members_articles').doc(id).set(data, { merge: true });

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};

// 会員記事の削除
export const useSetDeleteMembersArticle = async ({ main, sub, id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    if (!main || !sub || !id)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // データの削除（activeをfalseにする）
    const data = {
      active: false,
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
    };

    await db.collection('members_articles').doc(id).set(data, { merge: true });

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};

// 会員記事 更新ページの中の、添付ファイルの追加
export const useSetMembersFile = async ({ id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// idから既存データを取得 ////
    const doc = await db.collection('members_articles').doc(id).get();

    // データがない
    if (!doc.exists) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 取得したデータの格納
    const oldData = doc.data();
    // activeがfalseの場合は失敗
    if (!oldData || oldData.active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }
    // 古いfile情報を確認する
    const oldFiles = oldData.files;

    // 新しいファイル情報の抽出
    const newFile = body.newFile.document;

    // 登録するデータと場所（ref）
    const data = {
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      files: [...oldFiles, newFile], // 添付ファイル
    };
    const ref = db.collection('members_articles').doc(id);
    // トランザクション開始
    return db
      .runTransaction(async (t) => {
        // 記事のファイル情報の更新
        t.set(ref, data, { merge: true });

        // storageに追加する
        const image = body.newFile;
        await uploadFiles(image);

        return { status: 200, message: '' };
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 会員記事 更新ページの中の、添付ファイルの削除
export const useSetDeleteMembersFile = async ({ id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// idから既存データを取得 ////
    const doc = await db.collection('members_articles').doc(id).get();

    // データがない
    if (!doc.exists) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 取得したデータの格納
    const oldData = doc.data();
    // activeがfalseの場合は失敗
    if (!oldData || oldData.active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 古いfile情報を格納する
    const oldFiles = oldData.files;

    // 古いfile情報から、該当するファイルを削除した配列を作成
    const deletedFiles = oldFiles.filter((val) => {
      return val.storage_name !== body.deleteFile;
    });

    // seqを整理する
    const deletedSeqFiles = deletedFiles.map((val, index) => {
      return { ...val, seq: index + 1 };
    });

    // 登録するデータと場所（ref）
    const data = {
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      files: deletedSeqFiles, // 添付ファイル
    };
    const ref = db.collection('members_articles').doc(id);
    // トランザクション開始
    return db
      .runTransaction(async (t) => {
        // 記事のファイル情報の更新
        t.set(ref, data, { merge: true });

        // storageから削除する
        const storageRef = storage.ref('images/members/'); //どのフォルダの配下に入れるかを設定
        const imagesRef = storageRef.child(body.deleteFile); //ストレージファイル名
        await imagesRef.delete();

        return { status: 200, message: '' };
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 会員記事 更新ページの中の、添付ファイルの順番を確定する
export const useSetFilesSeq = async ({ id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// idから既存データを取得 ////
    const doc = await db.collection('members_articles').doc(id).get();

    // データがない
    if (!doc.exists) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 取得したデータの格納
    const oldData = doc.data();
    // activeがfalseの場合は失敗
    if (!oldData || oldData.active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 登録するデータと場所（ref）
    const data = {
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      files: body.files, // 添付ファイル
    };
    await db.collection('members_articles').doc(id).set(data, { merge: true });

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 添付ファイルのURLを取得
export const useGetFile = async (name) => {
  try {
    const path = `images/members/${name}`;
    const storageRef = storage.ref().child(path);
    const url = await storageRef.getDownloadURL();
    return { status: 200, data: url };
  } catch (error) {
    errorFunction('添付ファイルの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 理事会記事のデータ取得（更新アクセス時）
export const useGetDirectorsArticle = async (main, sub, id) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    // URLが正しくない場合は空で返す
    if (!id || !main || !sub) return { status: 200, data: {} };

    const doc = await db.collection('directors_articles').doc(id).get();

    // データがないときは空で返す
    if (!doc.exists) {
      return { status: 200, data: {} };
    }

    // 取得したデータの格納
    const data = doc.data();
    // activeがfalse、またはカテゴリが相違の場合は空で返す
    if (!data || data.active === false || data.main !== main || data.sub !== sub) {
      return { status: 200, data: {} };
    }

    // データがあったら返す
    const result = {
      id: doc.id,
      title: data.title, // タイトル
      view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
      updated_date: dayjs(data.updated_date.toDate()).format('YYYY/MM/DD HH:mm'), // 更新日
      contents: data.contents, // 本文
      view_flg: data.view_flg, //公開フラグ
      main: data.main,
      files: [],
    };
    // file情報があったら
    if (data.files.length >= 1) {
      // seqがある場合はseqで並び替えをする
      if (data.files[0].seq) {
        //先頭のファイルにseqが存在した場合
        result.files = data.files.sort((a, b) => a.seq - b.seq); //seq順でソートする
      } else {
        const newSeqFiles = data.files.map((file, index) => {
          return {
            file_name: file.file_name,
            storage_name: file.storage_name,
            seq: index + 1, //seqが無い場合は配列順で作成する
            file_size: file.file_size,
          };
        });
        // seqを一度セットする
        const setData = {
          updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
          updated_user_name: user.name, //データ更新者
          updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
          files: newSeqFiles, // 添付ファイル
        };
        await db.collection('directors_articles').doc(id).set(setData, { merge: true });
        result.files = newSeqFiles; // seqを追加しファイル情報
      }
    }
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

export const useSetDirectorsArticle = async ({ main, sub, id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    if (!main || !sub || !id)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // 2.記事データの作成
    const data = {
      title: body.title, // 件名
      view_flg: body.view_flg === 'true', // 公開フラグ
      view_date: body.view_date, // 公開日
      contents: body.contents, // 本文
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      active: true,
    };
    await db.collection('directors_articles').doc(id).set(data, { merge: true });

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};
// 理事会記事の削除
export const useSetDeleteDirectorsArticle = async ({ main, sub, id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    if (!main || !sub || !id)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // データの削除（activeをfalseにする）
    const data = {
      active: false,
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
    };

    await db.collection('directors_articles').doc(id).set(data, { merge: true });

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};
// 理事会記事 更新ページの中の、添付ファイルの追加
export const useSetDirectorsFile = async ({ id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// idから既存データを取得 ////
    const doc = await db.collection('directors_articles').doc(id).get();

    // データがない
    if (!doc.exists) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 取得したデータの格納
    const oldData = doc.data();
    // activeがfalseの場合は失敗
    if (!oldData || oldData.active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }
    // 古いfile情報を確認する
    const oldFiles = oldData.files;

    // 新しいファイル情報の抽出
    const newFile = body.newFile.document;

    // 登録するデータと場所（ref）
    const data = {
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      files: [...oldFiles, newFile], // 添付ファイル
    };
    const ref = db.collection('directors_articles').doc(id);
    // トランザクション開始
    return db
      .runTransaction(async (t) => {
        // 記事のファイル情報の更新
        t.set(ref, data, { merge: true });

        // storageに追加する
        const image = body.newFile;
        await uploadDirectorsFiles(image);

        return { status: 200, message: '' };
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};
// ファイルアップロード
const uploadDirectorsFiles = (image) => {
  return new Promise((resolve, reject) => {
    const storage_data = image.storage; // ストレージに保存するデータ
    const name = image.document.storage_name; // DBドキュメントに保存したストレージファイル名
    const storageRef = storage.ref('images/directors/'); //どのフォルダの配下に入れるかを設定
    const imagesRef = storageRef.child(name); //ストレージ用のファイル名を設定
    // ストレージにアップする
    const upLoadTask = imagesRef.put(storage_data);

    upLoadTask.on(
      'state_changed',
      function (snapshot) {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log(percent + '% done');
      },
      (error) => {
        console.error('ファイルアップに失敗しました。', error);
        reject();
      },
      () => {
        // complete: 完了したときの処理
        resolve();
      }
    );
  });
};
// 理事会記事 更新ページの中の、添付ファイルの削除
export const useSetDeleteDirectorsFile = async ({ id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// idから既存データを取得 ////
    const doc = await db.collection('directors_articles').doc(id).get();

    // データがない
    if (!doc.exists) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 取得したデータの格納
    const oldData = doc.data();
    // activeがfalseの場合は失敗
    if (!oldData || oldData.active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 古いfile情報を確認する
    const oldFiles = oldData.files;

    // 削除するファイルを除外した配列を作成
    const deletedFiles = oldFiles.filter((val) => {
      return val.storage_name !== body.deleteFile;
    });

    // seqを整理する
    const deletedSeqFiles = deletedFiles.map((val, index) => {
      return { ...val, seq: index + 1 };
    });

    // 登録するデータと場所（ref）
    const data = {
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      files: deletedSeqFiles, // 添付ファイル
    };
    const ref = db.collection('directors_articles').doc(id);
    // トランザクション開始
    return db
      .runTransaction(async (t) => {
        // 記事のファイル情報の更新
        t.set(ref, data, { merge: true });

        // storageから削除する
        const storageRef = storage.ref('images/directors/'); //どのフォルダの配下に入れるかを設定
        const imagesRef = storageRef.child(body.deleteFile); //ストレージファイル名
        await imagesRef.delete();

        return { status: 200, message: '' };
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};
// 添付ファイルのURLを取得
export const useGetDirectorsFile = async (name) => {
  try {
    const path = `images/directors/${name}`;
    const storageRef = storage.ref().child(path);
    const url = await storageRef.getDownloadURL();
    return { status: 200, data: url };
  } catch (error) {
    errorFunction('添付ファイルの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};
// カテゴリから記事一覧の取得
export const useGetDirectorsArticlesList = async (main, sub) => {
  try {
    const result = {}; // 取得するデータの格納
    result.category = []; // カテゴリを格納
    result.list = []; // 記事一覧を格納

    const today = cf.getToday(); // 今日の日付

    // URLが正しくない場合は空で返す
    if (!main || !sub) return { status: 200, data: [] };

    // カテゴリが正しいか確認
    const snapshot1 = await db
      .collection('directors_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    // 取得したデータの格納
    snapshot1.forEach(function (doc) {
      result.category.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub), //該当するサブカテゴリのみ抽出
      });
    });

    // メインカテゴリがなかったら空で返す
    if (result.category.length !== 1) return { status: 200, data: [] };
    // サブカテゴリがなかったら空で返す
    if (result.category.length >= 1 && result.category[0].sub_categories === undefined) {
      return { status: 200, data: [] };
    }

    // カテゴリから記事一覧を取得
    const snapshot2 = await db
      .collection('directors_articles')
      .where('main', '==', main)
      .where('sub', '==', sub)
      .where('active', '==', true)
      .orderBy('view_date', 'desc')
      .orderBy('updated_date', 'desc')
      .get();

    snapshot2.forEach(function (doc) {
      result.list.push({
        id: doc.id, // DocumentID
        title: doc.data().title, // 件名
        view_flg: doc.data().view_flg, // 公開フラグ
        view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
        main: doc.data().main, // メインカテゴリの英語名
        sub: doc.data().sub, // サブカテゴリの英語名
        main_Japanese: doc.data().main_Japanese, // メインカテゴリの日本語名
        sub_Japanese: doc.data().main_Japanese, // サブカテゴリの日本語名
      });
    });

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};
// 新規登録ページアクセス時の、会員カテゴリの確認
export const useGetDirectorsCategories = async (main, sub) => {
  try {
    // URLが正しくない場合は空で返す
    if (!main || !sub)
      return {
        status: 400,
        message: 'URLが無効です',
      };

    // カテゴリが正しいか確認
    const snapshot = await db
      .collection('directors_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      result.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub), //該当するサブカテゴリのみ抽出
      });
    });

    // メインカテゴリがなかったら空で返す
    if (result.length !== 1)
      return {
        status: 400,
        message: 'URLが無効です',
      };

    // サブカテゴリがなかったら空で返す
    if (result.length >= 1 && result[0].sub_categories === undefined) {
      return {
        status: 400,
        message: 'URLが無効です',
      };
    }

    return { status: 200, data: [] };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};
// 会員記事の新規登録
export const useAddDirectorsArticle = async ({ main, sub, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// URL（カテゴリ）が正しいかどうか確認 ////
    if (!main || !sub)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // カテゴリが正しいか確認
    const snapshot1 = await db
      .collection('directors_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    const category = []; // 取得するデータの格納
    snapshot1.forEach(function (doc) {
      category.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub), //該当するサブカテゴリのみ抽出
      });
    });

    // メインカテゴリがなかったら400で返す
    if (category.length !== 1)
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };
    // サブカテゴリがなかったら400で返す
    if (category.length >= 1 && category[0].sub_categories === undefined) {
      return {
        status: 400,
        message:
          'URLが正しくないため、登録が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    //// 記事の登録と、Storageへの登録 ////
    // 1.refの作成
    const ref = db.collection('directors_articles').doc();
    // 2.ファイルデータの抜き出し
    const document_files = body.files.map((file) => {
      return file.document;
    });

    // 2.記事データの作成
    const data = {
      title: body.title, // 件名
      view_flg: body.view_flg === 'true', // 公開フラグ
      view_date: body.view_date, // 公開日
      contents: body.contents, // 本文
      files: document_files, //添付ファイル情報
      main: main, // メインカテゴリ
      sub: sub, // サブカテゴリ
      main_Japanese: category[0].Japanese_name, // メインカテゴリ日本語名
      sub_Japanese: category[0].sub_categories.Japanese_name, //サブカテゴリ日本語名
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      active: true,
    };
    // トランザクション開始
    return db
      .runTransaction(async (t) => {
        // 記事の登録
        t.set(ref, data);

        // storageの登録
        for (let i = 0; i < body.files.length; i = (i + 1) | 0) {
          // アップロード処理
          const image = body.files[i];
          await uploadDirectorsFiles(image);
        }

        return { status: 200, message: '' };
      })
      .catch((error) => {
        // This will be an "population is too big" error.
        console.error(error);
        throw new Error(error);
      });
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};
// サイドメニュー用の理事会カテゴリとサブカテゴリの全データを取得
export const useGetDirectorsCategoriesAll = async () => {
  try {
    const today = cf.getToday(); // 今日の日付
    // URLが正しくない場合は空で返す

    const snapshot = await db
      .collection('directors_categories')
      .where('active', '==', true)
      .orderBy('seq')
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(async function (doc) {
      // サブカテゴリをseqの順番にソートする
      if (typeof doc.data().sub_categories[0].sub_seq !== 'undefined') {
        const sortedSubCategores = doc.data().sub_categories.sort((a, b) => a.sub_seq - b.sub_seq);
        result.push({
          name: doc.data().name, // メインの英語名
          Japanese_name: doc.data().Japanese_name, // メインの日本語名
          sub_categories: sortedSubCategores, // サブカテゴリの情報
        });
      } else {
        // Seqがなかったら保存する
        const currentId = doc.id;
        const newSeq = doc.data().sub_categories.map((val, index) => {
          return {
            ...val,
            sub_seq: index, //seqが無い場合は配列順で作成する
          };
        });
        // seqを一度セットする
        const setData = {
          sub_categories: newSeq, // サブカテゴリのみ変更
        };
        await db.collection('directors_categories').doc(currentId).set(setData, { merge: true });
        // 新しいサブカテゴリでデータを返す
        result.push({
          name: doc.data().name, // メインの英語名
          Japanese_name: doc.data().Japanese_name, // メインの日本語名
          sub_categories: newSeq, // サブカテゴリの情報
        });
      }
    });

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 理事会記事 更新ページの中の、添付ファイルの順番を確定する
export const useSetDirectorsFilesSeq = async ({ id, body }) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    //// idから既存データを取得 ////
    const doc = await db.collection('directors_articles').doc(id).get();

    // データがない
    if (!doc.exists) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 取得したデータの格納
    const oldData = doc.data();
    // activeがfalseの場合は失敗
    if (!oldData || oldData.active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 登録するデータと場所（ref）
    const data = {
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), //データ更新日
      updated_user_name: user.name, //データ更新者
      updated_user_ref: firebase.firestore().doc(`admin/${user.uid}`), // データ更新者パス
      files: body.files, // 添付ファイル
    };
    await db.collection('directors_articles').doc(id).set(data, { merge: true });

    return { status: 200, message: '' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 202407追加 中丸実
// 会員の一覧を取得
export const useGetUsersList = async (searchName = '', searchArea = '', selectNum = 200) => {
  try {
    // selectNum を数値に変換し、最大値を10,000に制限
    const searchNum = Math.min(parseInt(selectNum, 10), 10000);

    let baseQuery = db.collection('clinics').where('active', '==', true);

    if (searchArea) {
      baseQuery = baseQuery.where('area', '==', searchArea);
    }

    // kanaフィールドでのソートを追加
    baseQuery = baseQuery.orderBy('kana', 'asc');

    // 検索件数を取得（limitなしのクエリ）
    const totalSnapshot = await baseQuery.get();
    const searchSum = totalSnapshot.size;

    // 結果の上限を設定したクエリ
    const limitedQuery = baseQuery.limit(searchNum);
    const snapshot = await limitedQuery.get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      const data = doc.data();
      if (searchName === '' || data.name.includes(searchName)) {
        result.push({
          id: doc.id, // DocumentID
          active: doc.data().active, // アクティブフラグ
          name: doc.data().name, // 会員名
          area: doc.data().area, // 地域
          // date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'),
        });
      }
    });
    return { status: 200, data: result, searchSum: searchSum };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 202407追加 中丸実
// 会員の情報idから取得
export const useGetUserEdit = async (id, isEdit) => {
  try {
    //// ログイン情報の確認 ////
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    // URLにidが無い場合は空で返す
    if (!id) return { status: 200, data: {} };

    // 新規登録の場合、空で返す(isEditがfalse、かつidがnewの場合)
    if (isEdit === false && id === 'new') return { status: 200, data: {} };

    // 以下は編集の場合
    const clinisDoc = await db.collection('clinics').doc(id).get();

    // データがないときは空で返す
    if (!clinisDoc.exists) {
      return { status: 200, data: {} };
    }

    // 取得したデータの格納
    const clinicData = clinisDoc.data();
    // activeがfalseの場合は空で返す
    if (!clinicData || clinicData.active === false) {
      return { status: 200, data: {} };
    }

    // clinicDataにuser_refが存在しない場合
    if (!clinicData.user_ref) {
      return { status: 200, data: {} };
    }

    // user_refからユーザーデータを取得
    const userRef = clinicData.user_ref; // ユーザーの参照
    const userDoc = await userRef.get(); // ユーザーのドキュメントを取得

    // ユーザーのドキュメントが存在しない、または activeがfalseの場合は空で返す
    if (!userDoc.exists || userDoc.data().active === false) {
      return { status: 200, data: {} };
    }

    // ユーザーのデータを取得
    const userData = userDoc.data();
    clinicData.password = userData.password; // ログインパスワード

    // clinicData.updated_dateがあったら、日付フォーマット変更
    clinicData.updated_date = clinicData.updated_date
      ? dayjs(clinicData.updated_date.toDate()).format('YYYY/MM/DD HH:mm')
      : '';

    return {
      status: 200,
      data: clinicData,
    };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return {
      status: 500,
      message:
        'システムエラーが発生しました。管理者へエラーの通知を送信したため、しばらく経ってから再度表示してください',
    };
  }
};

// 202407追加 中丸実
// 会員情報の更新と新規登録
export const useSetUserEditAndNew = async ({ id, body }) => {
  try {
    // ログイン情報の確認
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    // URLにidが無い場合は空で返す
    if (!id) return { status: 200, data: {} };

    // bodyの格納
    const bodyClinicData = body.clinicData;
    const bodyUserData = body.userData;

    //////// 新規登録の場合 ////////
    if (id === 'new') {
      // パスワードが他のユーザと被っていないか確認（activeがtrueだけで確認）
      const snapshot = await db
        .collection('users')
        .where('password', '==', bodyUserData.password)
        .where('active', '==', true)
        .get();

      // 一つ以上存在していたらエラーを返す
      if (snapshot.docs.length > 0) {
        return {
          status: 400,
          message: 'パスワードが他のユーザーと重複しています。他のパスワードに変更してください。',
        };
      }

      // 会員情報の登録(clinics)
      const setClinicData = {
        ...bodyClinicData,
        active: true, // アクティブフラグ
        created_date: firebase.firestore.FieldValue.serverTimestamp(), // データ作成日
        updated_date: firebase.firestore.FieldValue.serverTimestamp(), // データ更新日
      };

      // ユーザー情報の登録(users)
      const setUserData = {
        ...bodyUserData, // password
        name: bodyClinicData.name, // 会員名
        active: true, // アクティブフラグ
        level: 'master', // レベル
        login_id: 'yamatoayaseDA', // ログインIDは固定
        created_date: firebase.firestore.FieldValue.serverTimestamp(), // データ作成日
        updated_date: firebase.firestore.FieldValue.serverTimestamp(), // データ更新日
      };

      // トランザクションの開始
      return db
        .runTransaction(async (t) => {
          // 先に、ユーザー情報の登録(users)を行うために、refを定義
          const userRef = db.collection('users').doc(); // ユーザーの参照
          // ユーザー情報の登録(users)
          t.set(userRef, setUserData);
          // clinicDataにuser_refをセット
          setClinicData.user_ref = userRef;

          // 会員情報の登録(clinics)
          t.set(db.collection('clinics').doc(), setClinicData);

          // 正常終了
          return { status: 200, message: '' };
        })
        .catch((error) => {
          console.error(error);
          throw new Error('トランザクション内のエラーです', error);
        });
    } else {
      //////// 更新の場合 ////////
      // idから既存データを取得
      const doc = await db.collection('clinics').doc(id).get();
      // データがないまたはactiveがfalse
      if (!doc.exists || doc.data().active === false) {
        return {
          status: 400,
          message:
            'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
        };
      }

      // user_refから既存ユーザーデータを取得
      const userRef = doc.data().user_ref; // ユーザーの参照
      const userDoc = await userRef.get(); // ユーザーのドキュメントを取得

      // ユーザーのドキュメントが存在しない、または activeがfalseの場合はやり直し
      if (!userDoc.exists || userDoc.data().active === false) {
        return {
          status: 400,
          message:
            'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
        };
      }

      // パスワードが他のユーザと被っていないか確認（activeがtrueだけで確認）
      const snapshot = await db
        .collection('users')
        .where('password', '==', bodyUserData.password)
        .where('active', '==', true)
        .get();

      // 一つ以上存在していて、かつログインIDが自分のIDと異なる場合、エラーを返す
      if (snapshot.docs.length > 0 && snapshot.docs[0].id !== userDoc.id) {
        return {
          status: 400,
          message: 'パスワードが他のユーザーと重複しています。他のパスワードに変更してください。',
        };
      }

      // 会員情報の更新(clinics)
      const setClinicData = {
        ...bodyClinicData,
        updated_date: firebase.firestore.FieldValue.serverTimestamp(), // データ更新日
      };

      // ユーザー情報の更新(users)
      const setUserData = {
        ...bodyUserData,
        name: bodyClinicData.name, // 会員名
        updated_date: firebase.firestore.FieldValue.serverTimestamp(), // データ更新日
      };

      // トランザクションの開始
      return db
        .runTransaction(async (t) => {
          // 会員情報の更新
          t.set(db.collection('clinics').doc(id), setClinicData, { merge: true });
          // ユーザー情報の更新
          t.set(userRef, setUserData, { merge: true });

          // throw new Error('わざとなエラーです');

          // 正常終了
          return { status: 200, message: '' };
        })
        .catch((error) => {
          console.error(error);
          throw new Error('トランザクション内のエラーです', error);
        });
    }
  } catch (error) {
    errorFunction('データの登録エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};

// 202407追加 中丸実
// 会員情報の削除
export const useSetDeleteUser = async ({ id, body }) => {
  try {
    // ログイン情報の確認
    const user = await validFirebaseAuth();
    if (!user) {
      Router.push('/sessionerror');
      return {
        status: 401,
        message: 'ログインエラーです',
      };
    }

    // URLにidが無い場合はエラーで返す
    if (!id)
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };

    // idから既存データを取得
    const doc = await db.collection('clinics').doc(id).get();
    // データがないまたはactiveがfalse
    if (!doc.exists || doc.data().active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // user_refから既存ユーザーデータを取得
    const userRef = doc.data().user_ref; // ユーザーの参照
    const userDoc = await userRef.get(); // ユーザーのドキュメントを取得

    // ユーザーのドキュメントが存在しない、または activeがfalseの場合はやり直し
    if (!userDoc.exists || userDoc.data().active === false) {
      return {
        status: 400,
        message:
          'URLが正しくないため、データの更新が失敗しました。もう一度、一覧画面からやり直してください',
      };
    }

    // 登録する会員情報
    const setClinicData = {
      active: false, // アクティブフラグ
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), // データ更新日
    };

    // 登録するユーザー情報
    const setUserData = {
      active: false, // アクティブフラグ
      updated_date: firebase.firestore.FieldValue.serverTimestamp(), // データ更新日
    };

    // トランザクションの開始
    return db
      .runTransaction(async (t) => {
        // 会員情報の更新
        t.set(db.collection('clinics').doc(id), setClinicData, { merge: true });
        // ユーザー情報の更新
        t.set(userRef, setUserData, { merge: true });

        // 正常終了
        return { status: 200, message: '' };
      })
      .catch((error) => {
        console.error(error);
        throw new Error('トランザクション内のエラーです', error);
      });
  } catch (error) {
    errorFunction('データの登録エラーです', error);
    return { status: 500, message: 'システムエラーが発生しましたため、登録できませんでした。' };
  }
};
