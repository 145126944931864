import React from 'react';
import axios from 'axios';
import Router, { useRouter } from 'next/router';
import qs from 'qs';

// method : get か post か…など
// data : post, put における、body
// params : get, delete におけるクエリ
// isFile : file操作するときに isFile = true を指定する
const customizeAxios = async ({ method, url, data, params, isFile, isS3, file_type }) => {
  let config = {};
  let result = {};
  const TIME_OUT = 1000 * 60; //60秒
  // const TIME_OUT = 1000 * 60 * 5; // 5分
  const S3_TIME_OUT = 1000 * 60 * 5; // 5分
  const MAX_FILE_SIZE = 5 * 1024 * 1024 * 1024; // 3GB

  const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

  // テキストとファイルを同時送信する場合
  if (isFile) {
    config = {
      method: method,
      url: url,
      data: data,
      params,
      paramsSerializer,
      headers: { 'content-type': 'multipart/form-data' },
      timeout: TIME_OUT,
      maxContentLength: MAX_FILE_SIZE,
    };
  } else if (isS3) {
    config = {
      method: method,
      url: url,
      data: data,
      params,
      paramsSerializer,
      headers: { 'content-type': file_type },
      timeout: S3_TIME_OUT,
      maxContentLength: MAX_FILE_SIZE,
    };
  } else {
    config = {
      method: method,
      url: url,
      data: data,
      params,
      paramsSerializer,
      timeout: TIME_OUT,
    };
  }

  try {
    const res = await axios(config);

    return (result = { status: 200, data: res.data });
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          // const indentMessage = error.response.data.message.split('\n').map((str, index) => (
          //        <React.Fragment key={index}>{str}<br /></React.Fragment>
          //      ))
          return (result = {
            status: 400,
            message: error.response.data.message,
          }); //INVALID_TOKEN

        case 401:
          Router.push('/sessionerror');
          return (result = { status: 401, message: 'ログインしていません' }); //UNAUTHORIZED
        case 302:
          return (result = {
            status: 302,
            message: error.response.data.message,
          });

        case 403:
          return (result = {
            status: 403,
            message: error.response.data.message,
          });

        default:
          //専用のページに飛ばす
          if (process.env.NODE_ENV === 'production') {
            Router.push('/systemerror');
          } else {
            alert(error.response.data.message);
          }
          return (result = {
            status: 500,
            message: '予期せぬエラーが発生しました。1',
          }); // UNHANDLED_ERROR
      }
    } else if (error.request) {
      // このリクエストはレスポンスが返ってこない時に作成されます。
      if (process.env.NODE_ENV === 'production') {
        Router.push('/systemerror');
      } else {
        alert('レスポンスエラー');
      }
      return (result = {
        status: 500,
        message: '予期せぬエラーが発生しました。2',
      });
    } else {
      //それ以外で何か異常が起こった時
      if (process.env.NODE_ENV === 'production') {
        Router.push('/systemerror');
      } else {
        alert('その他のエラー');
      }
      return (result = {
        status: 500,
        message: '予期せぬエラーが発生しました。3',
      });
    }
  } finally {
    // toggleLoad(false);
  }
};

export default customizeAxios;
